import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';
//import formatService from '@/services/formatters/articleFormatService'
import ErrorDialog from '@/components/core/ErrorDialog.vue';
export default {
  components: {
    ErrorDialog
  },
  data() {
    return {
      sm: null,
      location: null,
      warehouse: null,
      nullmenge: false,
      search: '',
      target: 0,
      allsm: [],
      allwarehouse: [],
      alllocation: [],
      scrollOptions: {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      },
      // Will be renewed
      headers: [{
        text: 'SM-ID',
        value: 'sm',
        align: 'start',
        sortable: true
      }, {
        text: 'SM1',
        value: 'sm1',
        align: 'start'
      }, {
        text: 'SM2',
        value: 'sm2',
        align: 'start'
      }, {
        text: 'SM3',
        value: 'sm3',
        align: 'start'
      }, {
        text: 'Lager',
        value: 'warehouse'
      }, {
        text: 'Lokation',
        value: 'location'
      }, {
        text: 'Bestand',
        value: 'inventory',
        align: 'end'
      }, {
        text: 'Reserviert',
        value: 'reserved',
        align: 'end'
      }, {
        text: 'Bestellt',
        value: 'ordered',
        align: 'end'
      }],
      failMessage: '',
      errorDialog: false
    };
  },
  computed: {
    StockItemsFiltered() {
      this.fillFilter();
      if (this.nullmenge == true) {
        return this.StockItems.filter(item => (!this.sm || item.sm === this.sm) && (!this.warehouse || item.warehouse === this.warehouse) && (!this.location || item.location === this.location) && parseFloat(item.inventory) !== 0.0);
      }
      return this.StockItems.filter(item => (!this.sm || item.sm === this.sm) && (!this.warehouse || item.warehouse === this.warehouse) && (!this.location || item.location === this.location));
    },
    ...mapState({
      StockItems: state => state.articleDetails.stock,
      loading: state => state.articleDetails.stockLoader,
      checkboxes: state => state.articleDetails.checkboxes
    })
  },
  methods: {
    init() {
      this.loadStock();
      this.fillFilter();
    },
    ResetFilter() {
      this.location = null;
      this.warehouse = null;
      this.sm = null;
    },
    displayItemTracking() {
      if (this.checkboxes.artInventoryManagement == false) {
        //displayVerkaufspreise = false
        this.StockItems.forEach(item => {
          item.inventory = null;
        });
      }
    },
    fillFilter() {
      this.StockItems.forEach(elem => {
        this.allsm.push(elem.sm);
        this.allwarehouse.push(elem.warehouse);
        this.alllocation.push(elem.location);
      });
      this.filterDisabled = false;
    },
    loadStock2() {
      try {
        this.$store.dispatch('GET_STOCK', this.$route.params.articleNumber);
      } catch (err) {
        if (err.request.status === 404) {
          console.log('No Stocks found');
        } else {
          this.failMessage = 'Beim Laden der Lagerbewegungen ist ein Fehler aufgetreten';
          this.errorDialog = true;
        }
      }
    },
    // Load Data
    async loadStock() {
      try {
        await this.$store.dispatch('GET_STOCK', this.$route.params.articleNumber);
      } catch (err) {
        if (err.request.status === 404) {
          console.log('No Stocks found');
        } else {
          this.failMessage = 'Beim Laden der Lagerbewegungen ist ein Fehler aufgetreten';
          this.errorDialog = true;
        }
      }
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    '$route.params': 'init'
  }
};