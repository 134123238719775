import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VRow, {
    staticClass: "mt-4 d-flex flex-wrap",
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VTextField, {
    attrs: {
      "placeholder": "Suche",
      "outlined": "",
      "width": "50",
      "clearable": "",
      "prepend-inner-icon": "mdi-magnify"
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c(VRow, {
    staticClass: "mt-n7 mb-4 d-flex align-center",
    attrs: {
      "no-gutters": ""
    }
  }, [_c(VExpansionPanels, {
    attrs: {
      "flat": ""
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, {
    staticClass: "border-left-primary-3",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(" Filter ")]), _c(VExpansionPanelContent, {
    staticStyle: {
      "margin-top": "30px"
    }
  }, [_c(VRow, {
    staticClass: "mt-n7 mb-4 d-flex align-center",
    attrs: {
      "no-gutters": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c(VAutocomplete, {
    staticClass: "mx-sm-1",
    attrs: {
      "items": _vm.allsm,
      "label": "Sachmerkmal",
      "clearable": "",
      "outlined": "",
      "small-chips": ""
    },
    model: {
      value: _vm.sm,
      callback: function ($$v) {
        _vm.sm = $$v;
      },
      expression: "sm"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c(VAutocomplete, {
    staticClass: "mx-sm-1",
    attrs: {
      "items": _vm.allwarehouse,
      "label": "Lager",
      "clearable": "",
      "outlined": "",
      "small-chips": ""
    },
    model: {
      value: _vm.warehouse,
      callback: function ($$v) {
        _vm.warehouse = $$v;
      },
      expression: "warehouse"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c(VAutocomplete, {
    staticClass: "mx-sm-1",
    attrs: {
      "items": _vm.alllocation,
      "label": "Lokation",
      "clearable": "",
      "outlined": "",
      "small-chips": ""
    },
    model: {
      value: _vm.location,
      callback: function ($$v) {
        _vm.location = $$v;
      },
      expression: "location"
    }
  })], 1), _c(VCol, [_c(VSwitch, {
    staticClass: "pl-3",
    attrs: {
      "inset": "",
      "label": `Nullmengen ausblenden`
    },
    model: {
      value: _vm.nullmenge,
      callback: function ($$v) {
        _vm.nullmenge = $$v;
      },
      expression: "nullmenge"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "12",
      "md": "6",
      "lg": "",
      "xl": ""
    }
  }, [_c(VBtn, {
    staticClass: "white--text",
    staticStyle: {
      "float": "right"
    },
    attrs: {
      "color": "red"
    },
    on: {
      "click": function ($event) {
        return _vm.ResetFilter();
      }
    }
  }, [_c(VIcon, [_vm._v(" mdi-close-thick ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)]), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VSheet, {
    attrs: {
      "width": "100vw"
    }
  }, [_c(VDataTable, {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.StockItemsFiltered,
      "loading": _vm.loading,
      "search": _vm.search,
      "loading-text": "Lagerbestände werden geladen"
    },
    scopedSlots: _vm._u([{
      key: "no-data",
      fn: function () {
        return [_vm._v(" Keine Lagerbestände gefunden. ")];
      },
      proxy: true
    }, {
      key: "no-results",
      fn: function () {
        return [_vm._v(" Keine Lagerbestände gefunden. ")];
      },
      proxy: true
    }, {
      key: `footer.prepend`,
      fn: function () {
        return [_c(VBtn, {
          staticClass: "ml-2 mb-2",
          attrs: {
            "small": "",
            "depressed": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$vuetify.goTo(_vm.target, _vm.scrollOptions);
            }
          }
        }, [_vm._v(" Nach oben "), _c(VIcon, [_vm._v(" mdi-arrow-up-thin ")])], 1)];
      },
      proxy: true
    }], null, true)
  })], 1)], 1)], 1), _c('ErrorDialog', {
    attrs: {
      "failMessage": _vm.failMessage,
      "opened": _vm.errorDialog
    },
    on: {
      "closeDialog": function ($event) {
        _vm.errorDialog = false;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };