import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VRow, {
    staticClass: "mt-4 d-flex flex-wrap"
  }, [_c(VCol, {
    attrs: {
      "xs": "12",
      "sm": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c(VTextField, {
    attrs: {
      "placeholder": "Suche",
      "outlined": "",
      "width": "50",
      "clearable": "",
      "prepend-inner-icon": "mdi-magnify"
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c(VRow, {
    staticClass: "mt-n7 mb-4 d-flex align-center",
    attrs: {
      "no-gutters": ""
    }
  }, [_c(VExpansionPanels, {
    attrs: {
      "flat": ""
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, {
    staticClass: "border-left-primary-3",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(" Filter ")]), _c(VExpansionPanelContent, {
    staticStyle: {
      "margin-top": "30px"
    }
  }, [_c(VRow, {
    staticClass: "mt-n7 mb-4 d-flex align-center",
    attrs: {
      "no-gutters": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "12",
      "md": "12",
      "lg": "5",
      "xl": "5"
    }
  }, [_c(VMenu, {
    ref: "menu1",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "max-width": "400px",
      "min-width": "auto",
      "origin": "center center"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on,
        attrs
      }) {
        return [_c(VTextField, _vm._g(_vm._b({
          staticClass: "mx-sm-1",
          attrs: {
            "label": "Startdatum",
            "persistent-hint": "",
            "append-icon": "mdi-calendar",
            "color": "primary",
            "clearable": "",
            "outlined": "",
            "value": _vm.startDateFormat
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.menu1,
      callback: function ($$v) {
        _vm.menu1 = $$v;
      },
      expression: "menu1"
    }
  }, [_c(VDatePicker, {
    attrs: {
      "color": "primary"
    },
    model: {
      value: _vm.startdate,
      callback: function ($$v) {
        _vm.startdate = $$v;
      },
      expression: "startdate"
    }
  }, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function ($event) {
        _vm.menu1 = false;
      }
    }
  }, [_vm._v("Abbrechen")]), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.menu1.save(_vm.date1);
      }
    }
  }, [_vm._v("Ok")])], 1)], 1)], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "12",
      "md": "12",
      "lg": "5",
      "xl": "5"
    }
  }, [_c(VMenu, {
    ref: "menu2",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "max-width": "400px",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on,
        attrs
      }) {
        return [_c(VTextField, _vm._g(_vm._b({
          staticClass: "mx-sm-1",
          attrs: {
            "label": "Enddatum",
            "persistent-hint": "",
            "append-icon": "mdi-calendar",
            "color": "primary",
            "outlined": "",
            "value": _vm.endDateFormat,
            "clearable": ""
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.menu2,
      callback: function ($$v) {
        _vm.menu2 = $$v;
      },
      expression: "menu2"
    }
  }, [_c(VDatePicker, {
    attrs: {
      "color": "primary"
    },
    model: {
      value: _vm.enddate,
      callback: function ($$v) {
        _vm.enddate = $$v;
      },
      expression: "enddate"
    }
  }, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function ($event) {
        _vm.menu2 = false;
      }
    }
  }, [_vm._v("Abbrechen")]), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.menu2.save(_vm.date2);
      }
    }
  }, [_vm._v("Ok")])], 1)], 1)], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "12",
      "md": "12 ",
      "lg": "",
      "xl": ""
    }
  }, [_c(VTooltip, {
    attrs: {
      "top": "",
      "color": "red"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on,
        attrs
      }) {
        return [_c(VBtn, _vm._g(_vm._b({
          staticClass: "white--text",
          staticStyle: {
            "float": "right"
          },
          attrs: {
            "color": "red"
          },
          on: {
            "click": function ($event) {
              return _vm.ResetFilter();
            }
          }
        }, 'v-btn', attrs, false), on), [_c(VIcon, [_vm._v(" mdi-close-thick ")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Filter löschen")])])], 1)], 1)], 1)], 1)], 1)], 1)], 1)]), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VSheet, {
    attrs: {
      "width": "100vw"
    }
  }, [_c(VDataTable, {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.OpenOffersItemsFiltered,
      "loading": _vm.loading,
      "item-key": "docNr",
      "search": _vm.search,
      "loading-text": "offene Angebote werden geladen"
    },
    scopedSlots: _vm._u([this.$store.getters.userHasApp('CRM') ? {
      key: "item.docNr",
      fn: function ({
        item
      }) {
        return [_c('router-link', {
          attrs: {
            "to": "#"
          },
          nativeOn: {
            "click": function ($event) {
              return _vm.openDocument(item.wf_cid, item.addressId);
            }
          }
        }, [_vm._v(" " + _vm._s(item.docNr) + " ")])];
      }
    } : null, this.$store.getters.userHasApp('CRM') ? {
      key: "item.customer",
      fn: function ({
        item
      }) {
        return [_c('router-link', {
          attrs: {
            "to": "#"
          },
          nativeOn: {
            "click": function ($event) {
              return _vm.openAddress(item.addressId);
            }
          }
        }, [_vm._v(" " + _vm._s(item.addressName) + " ")])];
      }
    } : {
      key: "item.customer",
      fn: function ({
        item
      }) {
        return [_c('span', [_vm._v(" " + _vm._s(item.addressName) + " ")])];
      }
    }, {
      key: "no-data",
      fn: function () {
        return [_vm._v(" Keine offenen Angebote gefunden ")];
      },
      proxy: true
    }, {
      key: "no-results",
      fn: function () {
        return [_vm._v(" Keine offenen Angebote gefunden ")];
      },
      proxy: true
    }, {
      key: `footer.prepend`,
      fn: function () {
        return [_c(VBtn, {
          staticClass: "ml-2 mb-2",
          attrs: {
            "small": "",
            "depressed": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$vuetify.goTo(_vm.target, _vm.scrollOptions);
            }
          }
        }, [_vm._v(" Nach oben "), _c(VIcon, [_vm._v(" mdi-arrow-up-thin ")])], 1)];
      },
      proxy: true
    }], null, true)
  })], 1)], 1)], 1), _c('ErrorDialog', {
    attrs: {
      "failMessage": _vm.failMessage,
      "opened": _vm.errorDialog
    },
    on: {
      "closeDialog": function ($event) {
        _vm.errorDialog = false;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };