import { mapState } from 'vuex';
import ErrorDialog from '@/components/core/ErrorDialog.vue';
export default {
  components: {
    ErrorDialog
  },
  data() {
    return {
      search: '',
      target: 0,
      scrollOptions: {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      },
      headers: [{
        text: 'SM-ID',
        value: 'lfdnr',
        sortable: true,
        align: 'start'
      }, {
        text: 'SM1',
        value: 'sm1',
        align: 'start'
      }, {
        text: 'SM2',
        value: 'sm2',
        align: 'start'
      }, {
        text: 'SM3',
        value: 'sm3',
        align: 'start'
      }, {
        text: 'EAN-Code',
        value: 'eanCode',
        align: 'start'
      }, {
        text: 'Mind.-Bestand',
        value: 'minStock',
        align: 'end'
      }, {
        text: 'VPE',
        value: 'vpe',
        align: 'end'
      }, {
        text: 'DEP',
        value: 'dep',
        align: 'end'
      }, {
        text: 'D-BZK',
        value: 'dbzk',
        align: 'end'
      }],
      failMessage: '',
      errorDialog: false
    };
  },
  computed: {
    ...mapState({
      characteristicsItems: state => state.articleDetails.characteristics,
      loading: state => state.articleDetails.characteristicsLoader
    })
  },
  methods: {
    init() {
      this.loadCharacteristics();
    },
    async loadCharacteristics() {
      try {
        await this.$store.dispatch('GET_CHARACTERISTICS', this.$route.params.articleNumber);
      } catch (err) {
        if (err.request.status === 404) {
          console.log('No Characteristics found');
        } else {
          this.failMessage = 'Beim Laden der Sachmerkmale ist ein Fehler aufgetreten';
          this.errorDialog = true;
        }
      }
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    '$route.params': 'init'
  }
};