import { quillEditor } from 'vue-quill-editor';
import ArticleCharacteristics from './articleDetailsTabs/ArticleCharacteristics.vue';
import ArticleSalePrices from './articleDetailsTabs/ArticleSalePrices.vue';
import ArticlePurchasePrice from './articleDetailsTabs/ArticlePurchasePrice.vue';
import ArticleMovements from './articleDetailsTabs/ArticleMovements.vue';
import ArticleCommissionedOrdered from './articleDetailsTabs/ArticleCommissionedOrdered.vue';
import ArticleStock from './articleDetailsTabs/ArticleStock.vue';
import ArticleOpen from './articleDetailsTabs/ArticleOpen.vue';
import ErrorDialog from '@/components/core/ErrorDialog.vue';
import { mapState } from 'vuex';
export default {
  components: {
    ArticleCharacteristics,
    ArticlePurchasePrice,
    ArticleSalePrices,
    ArticleMovements,
    ArticleCommissionedOrdered,
    ArticleStock,
    ArticleOpen,
    quillEditor,
    ErrorDialog
  },
  data() {
    return {
      tab: 0,
      tabs: ['Informationen', 'Sachmerkmale', 'Verkaufspreise', 'Einkaufspreise', 'Artikelbewegungen', 'BeauftragtBestellt', 'Lagerbestände', 'off. Angebote'],
      quillConfig: {
        modules: {
          toolbar: false
        },
        placeholder: '',
        readOnly: true,
        theme: 'snow'
      },
      checkboxitems: [
      // Hardcoded. Insert from Database later if needed!
      {
        id: 1,
        text: 'gesperrt',
        value: false
      }, {
        id: 2,
        text: 'Set',
        value: false
      }, {
        id: 3,
        text: 'Krankenkassenposition',
        value: false
      }, {
        id: 4,
        text: 'Bestandsführung',
        value: false
      }, {
        id: 5,
        text: 'Saison',
        value: false
      }, {
        id: 6,
        text: 'Objektsverwaltung',
        value: false
      }, {
        id: 7,
        text: 'Mengen-ID',
        value: false
      }, {
        id: 8,
        text: 'Produktionsartikel',
        value: false
      }, {
        id: 9,
        text: 'Provision',
        value: false
      }, {
        id: 10,
        text: 'Chargen',
        value: false
      }],
      failMessage: '',
      errorDialog: false,
      displayVerkaufspreise: false
    };
  },
  computed: {
    ...mapState({
      baseInformations: state => state.articleDetails.baseInformation,
      articleImages: state => state.articleDetails.articleImages,
      checkboxes: state => state.articleDetails.checkboxes,
      InventoryManagement: state => state.articleDetails.InventoryManagement,
      visibleTabs: state => state.articlePermissions.visibleTabs
    })
  },
  methods: {
    init() {
      this.loadArticleImages(), this.loadCheckboxes(), this.loadInventoryManagement();
    },
    loadArticleImages() {
      try {
        this.$store.dispatch('GET_ARTICLE_IMAGES', this.$route.params.articleNumber);
      } catch (err) {
        if (this.$store.state.articleDetails.articleExists) {
          this.failMessage = 'Beim Laden des Artikelbilds ist ein Fehler aufgetreten';
          this.errorDialog = true;
        }
      }
    },
    loadCheckboxes() {
      try {
        this.$store.dispatch('GET_CHECKBOXES', this.$route.params.articleNumber);
      } catch (err) {
        if (err.request.status === 404) {
          console.log('No Checkboxes found');
        } else {
          if (this.$store.state.articleDetails.articleExists) {
            this.failMessage = 'Beim Laden der Checkboxen ist ein Fehler aufgetreten';
            this.errorDialog = true;
          }
        }
      }
    },
    loadInventoryManagement() {
      try {
        this.$store.dispatch('GET_INVENTORYMANAGEMENT', this.$route.params.articleNumber);
      } catch (err) {
        if (err.request.status === 404) {
          console.log('No Inventory Management found');
        } else {
          if (this.$store.state.articleDetails.articleExists) {
            this.failMessage = 'Beim Laden der Bestandsführung ist ein Fehler aufgetreten';
            this.errorDialog = true;
          }
        }
      }
    }
  },
  watch: {
    '$route.params': 'init',
    tab() {
      const tab = this.tab;

      /*
      if (this.checkboxes.artInventoryManagement) {
        if (this.$route.query.tab != this.tabs[tab]+1) {
          this.$router.replace({ query: { tab: this.tabs[tab]+1 } })
        }
      } else {
        if (this.$route.query.tab != this.tabs[tab]) {
          this.$router.replace({ query: { tab: this.tabs[tab] } })
        }
      }*/

      //Improved
      const targetTab = this.checkboxes.artInventoryManagement ? this.tabs[tab] + 1 : this.tabs[tab];
      if (this.$route.query.tab !== targetTab) {
        this.$router.replace({
          query: {
            tab: targetTab
          }
        });
      }
    }
  },
  destroyed() {
    this.$store.commit('SET_ZERO');
  },
  mounted() {
    this.init();
  }
};