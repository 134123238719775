import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';
export default {
  data() {
    return {
      search: '',
      target: 0,
      scrollOptions: {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      },
      headers: [{
        text: 'Lieferant',
        value: 'supplier',
        sortable: true,
        align: 'start'
      }, {
        text: 'gilt ab',
        value: 'validity',
        align: 'start'
      }, {
        text: 'Sachmerkmal',
        value: 'characteristic',
        align: 'start'
      }, {
        text: 'Einkaufseinheit',
        value: 'purchasingUnit',
        align: 'start'
      }, {
        text: 'Faktor',
        value: 'factor',
        align: 'end'
      }, {
        text: 'Listenpreis',
        value: 'listprice',
        align: 'end'
      }, {
        text: 'Nettopreis',
        value: 'netprice',
        align: 'end'
      }, {
        text: 'Währung',
        value: 'currency',
        align: 'start'
      }, {
        text: 'Preiseinheit',
        value: 'priceUnit',
        align: 'center'
      }, {
        text: 'Rabatt',
        value: 'discount',
        align: 'end'
      }, {
        text: 'Rabatt-2',
        value: 'discount2',
        align: 'end'
      }, {
        text: 'Einkaufspreis',
        value: 'purchasePrice',
        align: 'end'
      }]
    };
  },
  computed: {
    ...mapState({
      PurchasePriceItems: state => state.articleDetails.purchaseprices,
      loading: state => state.articleDetails.purchasepricesLoader
    })
  },
  methods: {
    init() {
      this.$store.dispatch('GET_PURCHASEPRICES', this.$route.params.articleNumber);
    },
    openAddress(supplierId) {
      this.$router.push({
        name: 'crmAddress',
        params: {
          lfdnr: supplierId
        }
      });
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    '$route.params': 'init'
  }
};