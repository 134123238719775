import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VRow, {
    staticClass: "mt-4 d-flex flex-wrap",
    attrs: {
      "dense": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VTextField, {
    attrs: {
      "placeholder": "Suche",
      "outlined": "",
      "width": "50",
      "clearable": "",
      "prepend-inner-icon": "mdi-magnify"
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c(VRow, {
    staticClass: "mt-n7 mb-4 d-flex align-center",
    attrs: {
      "no-gutters": ""
    }
  }, [_c(VExpansionPanels, {
    attrs: {
      "flat": ""
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, {
    staticClass: "border-left-primary-3",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(" Filter ")]), _c(VExpansionPanelContent, {
    staticStyle: {
      "margin-top": "30px"
    }
  }, [_c(VRow, {
    staticClass: "mt-n7 mb-4 d-flex align-center",
    attrs: {
      "no-gutters": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "15",
      "xs": "15",
      "sm": "15",
      "md": "6",
      "lg": "4",
      "xl": "3"
    }
  }, [_c(VMenu, {
    ref: "menu1",
    staticClass: "mx-sm-1",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "max-width": "400px",
      "min-width": "auto",
      "origin": "center center"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on,
        attrs
      }) {
        return [_c(VTextField, _vm._g(_vm._b({
          staticClass: "mx-sm-1",
          attrs: {
            "label": "Startdatum",
            "persistent-hint": "",
            "append-icon": "mdi-calendar",
            "color": "primary",
            "clearable": "",
            "value": _vm.startDateFormat,
            "outlined": ""
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.menu1,
      callback: function ($$v) {
        _vm.menu1 = $$v;
      },
      expression: "menu1"
    }
  }, [_c(VDatePicker, {
    attrs: {
      "color": "primary"
    },
    model: {
      value: _vm.startdate,
      callback: function ($$v) {
        _vm.startdate = $$v;
      },
      expression: "startdate"
    }
  }, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function ($event) {
        _vm.menu1 = false;
      }
    }
  }, [_vm._v("Abbrechen")]), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.menu1.save(_vm.startDate);
      }
    }
  }, [_vm._v("Ok")])], 1)], 1)], 1), _c(VCol, {
    attrs: {
      "cols": "15",
      "xs": "15",
      "sm": "12",
      "md": "6",
      "lg": "4",
      "xl": "3"
    }
  }, [_c(VMenu, {
    ref: "menu2",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "max-width": "400px",
      "min-width": "auto",
      "origin": "center center"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on,
        attrs
      }) {
        return [_c(VTextField, _vm._g(_vm._b({
          staticClass: "mx-sm-1",
          attrs: {
            "label": "Enddatum",
            "persistent-hint": "",
            "append-icon": "mdi-calendar",
            "color": "primary",
            "clearable": "",
            "value": _vm.endDateFormat,
            "outlined": ""
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.menu2,
      callback: function ($$v) {
        _vm.menu2 = $$v;
      },
      expression: "menu2"
    }
  }, [_c(VDatePicker, {
    attrs: {
      "color": "primary"
    },
    model: {
      value: _vm.enddate,
      callback: function ($$v) {
        _vm.enddate = $$v;
      },
      expression: "enddate"
    }
  }, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function ($event) {
        _vm.menu2 = false;
      }
    }
  }, [_vm._v("Abbrechen")]), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.menu2.save(_vm.date2);
      }
    }
  }, [_vm._v("Ok")])], 1)], 1)], 1), _c(VCol, {
    attrs: {
      "cols": "15",
      "xs": "15",
      "sm": "12",
      "md": "6",
      "lg": "4",
      "xl": "3"
    }
  }, [_c(VAutocomplete, {
    staticClass: "mx-sm-1",
    attrs: {
      "items": _vm.alldocument,
      "label": "Dokumentart",
      "clearable": "",
      "outlined": "",
      "origin": "center center",
      "small-chips": ""
    },
    model: {
      value: _vm.document,
      callback: function ($$v) {
        _vm.document = $$v;
      },
      expression: "document"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "15",
      "xs": "15",
      "sm": "12",
      "md": "6",
      "lg": "4",
      "xl": "3"
    }
  }, [_c(VAutocomplete, {
    staticClass: "mx-sm-1",
    attrs: {
      "items": _vm.allsm,
      "label": "Sachmerkmal",
      "clearable": "",
      "outlined": "",
      "origin": "center center",
      "small-chips": ""
    },
    model: {
      value: _vm.sm,
      callback: function ($$v) {
        _vm.sm = $$v;
      },
      expression: "sm"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "15",
      "xs": "15",
      "sm": "12",
      "md": "6",
      "lg": "4",
      "xl": "3"
    }
  }, [_c(VAutocomplete, {
    staticClass: "mx-sm-1",
    attrs: {
      "items": _vm.allcustomer,
      "label": "Kunde",
      "clearable": "",
      "outlined": "",
      "origin": "center center",
      "small-chips": ""
    },
    model: {
      value: _vm.customer,
      callback: function ($$v) {
        _vm.customer = $$v;
      },
      expression: "customer"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "15",
      "xs": "15",
      "sm": "12",
      "md": "6",
      "lg": "4",
      "xl": "3"
    }
  }, [_c(VAutocomplete, {
    staticClass: "mx-sm-1",
    attrs: {
      "items": _vm.allsupplier,
      "label": "Lieferant",
      "clearable": "",
      "outlined": "",
      "origin": "center center",
      "small-chips": ""
    },
    model: {
      value: _vm.supplier,
      callback: function ($$v) {
        _vm.supplier = $$v;
      },
      expression: "supplier"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "15",
      "xs": "15",
      "sm": "12",
      "md": "6",
      "lg": "4",
      "xl": "3"
    }
  }, [_c(VAutocomplete, {
    staticClass: "mx-sm-1",
    attrs: {
      "items": _vm.allmovement,
      "return-object": "",
      "item-text": "fldDisplayBez",
      "label": "Bewegungsart",
      "clearable": "",
      "outlined": "",
      "origin": "center center",
      "small-chips": ""
    },
    model: {
      value: _vm.movement,
      callback: function ($$v) {
        _vm.movement = $$v;
      },
      expression: "movement"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "15",
      "xs": "15",
      "sm": "12",
      "md": "6",
      "lg": "",
      "xl": ""
    }
  }, [_c(VTooltip, {
    attrs: {
      "top": "",
      "color": "red"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on,
        attrs
      }) {
        return [_c(VBtn, _vm._g(_vm._b({
          staticClass: "white--text mx-sm-1",
          staticStyle: {
            "float": "right"
          },
          attrs: {
            "sm": "12",
            "color": "red"
          },
          on: {
            "click": function ($event) {
              return _vm.ResetFilter();
            }
          }
        }, 'v-btn', attrs, false), on), [_c(VIcon, [_vm._v(" mdi-close-thick ")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Filter löschen")])])], 1)], 1)], 1)], 1)], 1)], 1)], 1)]), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VDataTable, {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.TransportItemsFiltered,
      "loading": _vm.loading,
      "search": _vm.search,
      "loading-text": "Artikelbewegungen werden geladen"
    },
    scopedSlots: _vm._u([this.$store.getters.userHasApp('Business') ? {
      key: "item.docNr",
      fn: function ({
        item
      }) {
        return [item.wfcId ? [_c('a', {
          attrs: {
            "href": _vm.getDocumentLink(item.wfcId, item.kdnr),
            "target": "_blank"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openDocument(item.wfcId, item.kdnr);
            }
          }
        }, [_vm._v(" " + _vm._s(item.docNr) + " ")])] : [_vm._v(" " + _vm._s(item.docNr) + " ")]];
      }
    } : null, this.$store.getters.userHasApp('CRM') ? {
      key: "item.customerName",
      fn: function ({
        item
      }) {
        return [_c('router-link', {
          attrs: {
            "to": "#"
          },
          nativeOn: {
            "click": function ($event) {
              return _vm.openAddress(item.kdnr);
            }
          }
        }, [_vm._v(" " + _vm._s(item.customerName) + " ")])];
      }
    } : null, this.$store.getters.userHasApp('CRM') ? {
      key: "item.supplierName",
      fn: function ({
        item
      }) {
        return [_c('router-link', {
          attrs: {
            "to": "#"
          },
          nativeOn: {
            "click": function ($event) {
              return _vm.openAddress(item.supplierId);
            }
          }
        }, [_vm._v(" " + _vm._s(item.supplierName) + " ")])];
      }
    } : null, {
      key: "no-data",
      fn: function () {
        return [_vm._v(" Keine Artikelbewegungen gefunden. ")];
      },
      proxy: true
    }, {
      key: "no-results",
      fn: function () {
        return [_vm._v(" Keine Artikelbewegungen gefunden. ")];
      },
      proxy: true
    }, {
      key: `footer.prepend`,
      fn: function () {
        return [_c(VBtn, {
          staticClass: "ml-2 mb-2",
          attrs: {
            "small": "",
            "depressed": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$vuetify.goTo(_vm.target, _vm.scrollOptions);
            }
          }
        }, [_vm._v(" Nach oben "), _c(VIcon, [_vm._v(" mdi-arrow-up-thin ")])], 1)];
      },
      proxy: true
    }], null, true)
  })], 1)], 1), _c('ErrorDialog', {
    attrs: {
      "failMessage": _vm.failMessage,
      "opened": _vm.errorDialog
    },
    on: {
      "closeDialog": function ($event) {
        _vm.errorDialog = false;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };