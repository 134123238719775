import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [_vm.articleExists ? _c('div', [_c(VRow, {
    staticClass: "w-100"
  }, [_c(VCol, {
    staticClass: "d-flex flex-column align-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('SiteHeaderComponent', {
    staticClass: "mb-8",
    attrs: {
      "header": _vm.siteHeader
    }
  }), _c(VTabs, {
    attrs: {
      "grow": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c(VTab, {
    key: "AllgemeinTab"
  }, [_vm._v("Allgemein")]), _c(VTab, {
    key: "StatistikTab"
  }, [_vm._v("Statistik")])], 1), _c(VTabsItems, {
    attrs: {
      "grow": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c(VTabItem, {
    key: "AllgemeinTab"
  }, [_c('ArticleDetails')], 1), _c(VTabItem, {
    key: "StatistikTab"
  }, [_c('ArticleStatistics')], 1)], 1)], 1)], 1)], 1) : _c('div', [_c(VCard, {
    staticClass: "pa-5 mt-16",
    staticStyle: {
      "border-radius": "15px"
    },
    attrs: {
      "elevation": "0"
    }
  }, [_c(VLayout, {
    attrs: {
      "column": "",
      "align-center": "",
      "justify-center": "",
      "fill-height": ""
    }
  }, [_c(VFlex, {
    staticClass: "text-center mb-4",
    attrs: {
      "xs12": ""
    }
  }, [_c('h3', [_vm._v(" Der gesuchte Artikel konnte nicht gefunden werden ")])]), _c(VFlex, {
    attrs: {
      "xs12": ""
    }
  }, [_c(VImg, {
    staticClass: "mx-auto my-auto",
    attrs: {
      "src": require("@/assets/svg/undraw_warning_re_eoyh.svg"),
      "max-width": "350px",
      "contain": ""
    }
  })], 1), _c(VFlex, {
    staticClass: "text-center mt-4",
    attrs: {
      "xs12": ""
    }
  }, [_c(VBtn, {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.navigateToArticleSearch();
      }
    }
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-magnify")]), _vm._v(" Zurück zur Artikelsuche ")], 1)], 1)], 1)], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };