/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ArticleDetails.vue?vue&type=template&id=98a6d33c&scoped=true"
import script from "./ArticleDetails.vue?vue&type=script&lang=js"
export * from "./ArticleDetails.vue?vue&type=script&lang=js"
import style0 from "./ArticleDetails.vue?vue&type=style&index=0&id=98a6d33c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98a6d33c",
  null
  
)

export default component.exports